<template>
  <v-dialog
    v-model="isMainBioDialog"
    hide-overlay
    fullscreen
    transition="dialog-bottom-transition"
  >
    <!-- :fullscreen="$vuetify.breakpoint.mdAndDown" -->
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="processStep === 1"
        fab
        dark
        small
        v-bind="attrs"
        :color="statusColor[`${visitStore.visitData.status[0].value}`]"
        v-on="on"
      >
        <v-icon color="white">
          {{ icons.mdiFileDocumentEditOutline }}
        </v-icon>
      </v-btn>
      <v-btn
        v-if="processStep === 2"
        fab
        dark
        small
        v-bind="attrs"
        :color="statusColor[`${visitStore.visitData.status[1].value}`]"
        v-on="on"
      >
        <v-icon color="white">
          {{ icons.mdiLanPending }}
        </v-icon>
      </v-btn>
      <button
        v-if="processStep === 5"
        v-bind="attrs"
        class="tw-w-full sm:tw-w-auto tw-mt-12 md:tw-mt-10 tw-bg-gray-800 tw-py-3 tw-px-6 tw-text-base tw-font-medium tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-800 hover:tw-bg-gray-700"
        v-on="on"
      >
        Start Here <v-icon
          dark
          right
        >
          {{ icons.mdiArrowRight }}
        </v-icon>
      </button>
      <button
        v-if="processStep === 6"
        v-bind="attrs"
        class="tw-w-full sm:tw-w-auto tw-mt-12 md:tw-mt-10 tw-bg-gray-800 tw-py-3 tw-px-6 tw-text-base tw-font-medium tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-800 hover:tw-bg-gray-700"
        v-on="on"
      >
        Update Profile <v-icon
          dark
          right
        >
          {{ icons.mdiAccountEdit }}
        </v-icon>
      </button>
    </template>
    <!-- :fullscreen="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smAndDown" -->
    <v-card tile>
      <v-toolbar
        dark
        color="primary"
        dense
        outlined
        flat
      >
        <!-- FIXME removed x -->
        <!-- <v-btn
          icon
          dark
          @click="closeDialogBoxX"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn> -->

        <v-toolbar-title>
          <span class="title">{{ this.visitStore.patientData.name }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-stepper
        id="profileActionStepper"
        v-model="activeStep"
      >
        <v-stepper-header>
          <!-- Step 1 -->
          <v-stepper-step
            :complete="activeStep > 1"
            step="1"
          >
            <div class="d-flex align-center">
              <span class="text--primary text-4xl font-weight-bold me-3">01</span>
              <div class="d-flex flex-column">
                <span class="text--primary text-sm font-weight-semibold">Your Details</span>
                <span class="text--secondary text-xs">Update your information</span>
              </div>
            </div>
          </v-stepper-step>
          <v-divider></v-divider>
          <!-- Step 2 -->
          <v-stepper-step
            :complete="activeStep > 2"
            step="2"
          >
            <div class="d-flex align-center">
              <span class="text--primary text-4xl font-weight-bold me-3">02</span>
              <div class="d-flex flex-column">
                <span class="text--primary text-sm font-weight-semibold">Medical Info</span>
                <span class="text--secondary text-xs">Brief Medical Questions</span>
              </div>
            </div>
          </v-stepper-step>
          <v-divider></v-divider>
          <!-- Step 3 -->
          <v-stepper-step
            :complete="activeStep > 3"
            step="3"
          >
            <div class="d-flex align-center">
              <span class="text--primary text-4xl font-weight-bold me-3">03</span>
              <div class="d-flex flex-column">
                <span class="text--primary text-sm font-weight-semibold">Insurance</span>
                <span class="text--secondary text-xs">Tell us about your insurance</span>
              </div>
            </div>
          </v-stepper-step>
          <v-divider></v-divider>
          <!-- Step 4 -->
          <v-stepper-step
            :complete="activeStep > 4"
            step="4"
          >
            <div class="d-flex align-center">
              <span class="text--primary text-4xl font-weight-bold me-3">04</span>
              <div class="d-flex flex-column">
                <span class="text--primary text-sm font-weight-semibold">Processing</span>
                <span class="text--secondary text-xs">Let's fill your order</span>
              </div>
            </div>
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items id="ProfileActionStepperItems">
          <v-stepper-content
            id="profileContent"
            step="1"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                offset-md="3"
              >
                <basic-profile
                  :is-bio-dialog-open="isBioDialogOpen"
                  @next="next($event)"
                ></basic-profile>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content
            id="medicalContent"
            step="2"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                offset-md="3"
              >
                <medical-history
                  :is-bio-dialog-open="isBioDialogOpen"
                  @next="next($event)"
                  @previous="previous($event)"
                ></medical-history>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content
            id="insranceContent"
            step="3"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                offset-md="3"
              >
                <insurance-info
                  :is-bio-dialog-open="isBioDialogOpen"
                  @previous="previous($event)"
                  @closeDialog="closeDialog($event)"
                  @next="next($event)"
                ></insurance-info>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content
            id="fillOrderContent"
            step="4"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <fill-call-to-action
                  @closeDialog="closeDialogBox($event)"
                  @previous="previous($event)"
                ></fill-call-to-action>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import {
    mdiClose, mdiFileDocumentEditOutline, mdiLanPending, mdiArrowRight, mdiAccountEdit
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import basicProfile from '@/components/Forms/basicProfile.vue'
import insuranceInfo from '@/components/Forms/insuranceInfo.vue'
import { visitService } from '@/api/index.js'
import { useVisitStore } from '@/stores/Visit.js'
import { splitFullName } from '@/util/functions'
import medicalHistory from '@/components/Forms/medicalHistory.vue'
import { statusColor, statusLabel } from '@/assets/lists'
import fillCallToAction from '@/components/Forms/fillCallToAction.vue'

export default {
    name: 'ProfileAction',
    components: {
        basicProfile,
        insuranceInfo,
        medicalHistory,
        fillCallToAction,
    },
    props: {
        isMainBioDialogOpen: {
            type: Boolean,
            default: true,
        },
        emitStep: {
            type: Number,
            default: 1,
        },
        processStep: {
            type: Number,
            default: 1,
        },
    },
    setup() {
        const visitStore = useVisitStore()

        // const isMainBioDialog = ref(false)

        // const isMainBioDialog = this.isMainBioDialogOpen

        return {
            // isMainBioDialog,
            visitStore,
            statusColor,
            statusLabel,
        }
    },
    data() {
        return {
            isMainBioDialog: this.isMainBioDialogOpen,
            activeStep: this.emitStep || 1, // FIXME swithc back to 1
            icons: {
                mdiClose,
                mdiFileDocumentEditOutline,
                mdiLanPending,
                mdiArrowRight,
                mdiAccountEdit
            },
        }
    },
    computed: {
        isBioDialogOpen(val) {
            if (this.activeStep === 1) {
                return true
            }
            if (val) {
                return false
            }

            return false
        },
        patient() {
            const splitName = splitFullName(this.visitStore.patientData.name)

            return splitName
        },
        patientData() {
            return this.visitStore.patientData
        },

    // isMainBioDialog() {
    //     return this.isMainBioDialogOpen
    // },
    },
    watch: {
        isMainBioDialogOpen(val) {
            this.isMainBioDialog = val
        },
        isMainBioDialog(val) {
            // this.$root.$emit('updateMainBioDialog', val)
            if (val) {
                this.$zendesk.hide()
            }

            // this.$zendesk.show()
        },
        emitStep(val) {
            if (val) {
                this.activeStep = val
            }
        },
    },
    mounted() {},
    methods: {
        async updateVisitStatus(data) {
            const statusData = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                visitId: this.visitStore.visitId,
                patientId: this.visitStore.patientData.id,
                basic: data.basic || 'pending',

                // processing: data.processing || 'pending',
                // payment: data.payment || 'pending',
                // delivery: data.delivery || 'pending',
            }
            await visitService.updateVisitStatus(statusData)
            await this.visitStore.setVisit({ id: statusData.visitId, dob: statusData.dateofBirth })
        },
        closeDialogBoxX(event) {
            this.isMainBioDialog = false
            this.$zendesk.show()

            this.$emit('updateMainBioDialog', false)

            // await this.closeDialog()
        },
        async closeDialogBox(event) {
            this.isMainBioDialog = false
            this.$zendesk.show()

            // this.$emit('updateMainBioDialog', false)
            await this.closeDialog()
        },
        async closeDialog() {
            await this.visitStore.setVisit({ id: this.visitStore.visitId, dob: this.visitStore.patientData.dateofBirth })
            if (this.visitStore.patientData.id === this.visitStore.visitData.patientId) {
                // this.isMainBioDialogOpen = false
                if (this.visitStore.patientData?.detailedAddress && (this.visitStore.patientData?.hasAllergy === 0 || this.visitStore.patientData?.hasAllergy === 1) && (this.visitStore.patientData?.takesMeds === 0 || this.visitStore.patientData?.takesMeds === 1) && (this.visitStore.patientData?.insuranceStatus === 0 || this.visitStore.patientData?.insuranceStatus === 1)) {
                    const data = {
                        dateofBirth: this.visitStore.patientData.dateofBirth, // need to update this
                        visitId: this.visitStore.visitId, // need to update this
                        patientId: this.visitStore.patientData.id, // need to update this
                        basic: 'complete',

                    // processing: 'inprogress',
                    // payment: 'pending',
                    // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                } else {
                    const data = {
                        dateofBirth: this.visitStore.patientData.dateofBirth, // need to update this
                        visitId: this.visitStore.visitId, // need to update this
                        patientId: this.visitStore.patientData.id, // need to update this
                        basic: 'pending',

                        // processing: 'pending',
                        // payment: 'pending',
                        // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                }
            }

            // this.$emit('closeDialog', false)
            // this.$emit('update:is-bio-dialog-open', false)
            // this.$emit('updateMainBioDialog', false)
        },
        previous(step) {
            this.activeStep = step
        },
        next(step) {
            this.activeStep = step
        },
    },
}
</script>

<style lang="scss" scoped>
#profileContent, #medicalContent, #insranceContent, #fillOrderContent {
    top: 0;
    padding: 0px !important;
    flex: 1 0 auto;
    width: 100%;
}

#insranceContent {
  margin: -8px -28px -16px -4px;
}
</style>
