<template>
  <v-card>
    <!-- <v-card-title>
      <span class="tw-break-words"><strong>Your</strong> prescription prepared <strong>Your</strong> way</span>
    </v-card-title> -->

    <v-container>
      <div

        class="2xl:tw-container 2xl:tw-mx-auto lg:tw-px-20 md:tw-py-12 md:tw-px-6  tw-px-2"
      >
        <div class="tw-text-center">
          <h2 class="lg:tw-text-xl dark:tw-text-white tw-text-xl lg:tw-leading-9 tw-leading-7 tw-font-semibold tw-text-gray-800 tw-pb-3">
            Prescription Fulfillment
          </h2>
        </div>
        <p class="tw-font-normal dark:tw-text-gray-400 tw-text-base lg:tw-leading-4 tw-leading-6 tw-text-gray-800 lg:tw-mt-4 md:tw-mt-2 ">
          Your health and well being are our priority. Our prescription fulfillment process is designed to ensure that your prescription is delivered to you in a timely manner.
        </p>

        <div
          v-if="!disabled"
          class="tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-grid-cols-2 lg:tw-gap-8 tw-gap-10 lg:tw-mt-16 tw-mt-10"
        >
          <!-- Order Online Grid Card -->
          <div class="tw-flex tw-flex-col tw-justify-start tw-items-center">
            <v-img
              id="portalImage"
              :class="overlayClass"
              contain
              src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/online%20shop_online%20support-customer%20service-call%20center-operator-customer%20support.png"
              alt="Order Online"
            >
            </v-img>
            <p class=" dark:tw-text-white tw-text-center tw-text-xl tw-text-gray-800 tw-font-semibold tw-leading-5 tw-mt-6">
              Online Portal
            </p>
            <p
              v-if="!$vuetify.breakpoint.mdAndDown"
              class="tw-text-center dark:tw-text-gray-400 tw-font-normal tw-text-base tw-leading-6 tw-text-gray-600 tw-mt-4"
            >
              Get live updates on your prescription status
            </p>
          </div>

          <!-- Personalization Grid Card -->
          <div class="tw-flex tw-flex-col tw-justify-start tw-items-center">
            <v-img
              id="cocImage"
              :class="overlayClass"
              contain
              src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/hospital-building-medical-health%20clinic-urban.png"
              alt="Personalisation"
            >
            </v-img>
            <p class=" dark:tw-text-white tw-text-center tw-text-xl tw-text-gray-800 tw-font-semibold tw-leading-5 tw-mt-6">
              Circle of Care
            </p>
            <p
              v-if="!$vuetify.breakpoint.mdAndDown"
              class="tw-text-center dark:tw-text-gray-400 tw-font-normal tw-text-base tw-leading-6 tw-text-gray-600 tw-mt-4 lg:tw-pr-0 tw-pr-10"
            >
              Drug monitoring and personalization
            </p>
          </div>

          <!-- Shopping Grid Card -->
          <div
            class="tw-flex tw-flex-col tw-justify-start tw-items-center"
          >
            <v-img
              id="deliveryImage"
              :class="overlayClass"
              contain
              src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/Icon%20Color_5.png"
              alt="Delivery"
            >
            </v-img>
            <p class=" dark:tw-text-white tw-text-center tw-text-xl tw-text-gray-800 tw-font-semibold tw-leading-5 tw-mt-6">
              Free Delivery
            </p>
            <p
              v-if="!$vuetify.breakpoint.mdAndDown"
              class="tw-text-center dark:tw-text-gray-400 tw-font-normal tw-text-base tw-leading-6 tw-text-gray-600 tw-mt-4"
            >
              Free Delivery across Canada
            </p>
          </div>

          <!-- Delivery Grid Card -->
          <div
            class="tw-flex tw-flex-col tw-justify-start tw-items-center"
          >
            <v-img
              id="samplesImages"
              :class="overlayClass"
              contain
              src="https://pharma-guide-portal.nyc3.digitaloceanspaces.com/images/16%20Ointment.png"
              alt="Delivery"
            >
            </v-img>
            <p class=" dark:tw-text-white tw-text-center tw-text-xl tw-text-red-800 tw-font-semibold tw-leading-5 tw-mt-6">
              Skincare Samples
            </p>
            <p
              v-if="!$vuetify.breakpoint.mdAndDown"
              class="tw-text-center dark:tw-text-gray-400 tw-font-normal tw-text-base tw-leading-6 tw-text-red-600 tw-mt-4 lg:tw-pr-0 tw-pr-10"
            >
              Skincare Samples as per your prescription with every order
            </p>
          </div>
        </div>
      </div>
      <v-alert
        v-if="disabled && radios ==='other'"
        color="warning"
        dark
      >
        <div>
          <span class=" tw-font-bold tw-text-gray-900 tw-text-lg">
            This prescription was successfully sent to Another Pharmacy. Please contact them for further assistance.
          </span>
          <div class="tw-pt-6 d-flex tw-justify-center">
            <v-btn
              color="success"
              :to="{name: 'transfer'}"
            >
              <v-icon
                left
                large
                color="white"
              >
                {{ icons.mdiFax }}
              </v-icon>
              <span class=" tw-text-base "> Transmit Report</span>
            </v-btn>
          </div>
        </div>
      </v-alert>
      <v-alert
        v-if="disabled && radios ==='pharmaSmart'"
        color="success"
        dark
      >
        <v-row>
          <v-col
            cols="10"
            sm="12"
          >
            <div>
              <v-card-text>
                <span
                  id="wordBreak"
                  class=" tw-font-bold tw-text-white md:tw-text-lg tw-text-sm "
                >
                  This prescription is being prepared by PharmaSmart! You will receive a notification if payment is needed or a shipping notification if no payment is needed.
                </span>
              </v-card-text>
              <div class="tw-pt-6 tw-px-6 d-flex tw-justify-center">
                <v-btn
                  color="info"
                  @click="help"
                >
                  <v-icon
                    left

                    color="white"
                  >
                    {{ icons.mdiFax }}
                  </v-icon>
                  <span class=" tw-text-base "> Questions? Click here</span>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-alert>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          offset="1"
          offset-md="4"
        >
          <v-radio-group
            v-model="radios"
            :disabled="disabled"
          >
            <template v-slot:label>
              <div class="tw-text-lg tw-pr-8 md:tw-pr-0 tw-text-gray-800 tw-break-words">
                <strong>Please select how you would like your prescriptions filled</strong>
              </div>
            </template>
            <v-radio value="pharmaSmart">
              <template v-slot:label>
                <div>
                  <strong class="primary--text">PharmaSmart</strong>
                  <v-icon
                    dark
                    right
                    class="tw-mb-2"
                    color="primary"
                  >
                    {{ icons.mdiBottleTonicPlus }}
                  </v-icon>
                </div>
              </template>
            </v-radio>
            <v-radio value="other">
              <template v-slot:label>
                <div><strong class="text">Other Pharmacy</strong></div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions>
      <v-col
        cols="12"
        class="d-flex justify-center mt-3"
      >
        <div v-if="prevButton">
          <v-btn
            vue-tour-target="previousButton"
            outlined
            class="me-2"
            @click="previous"
          >
            Previous
          </v-btn>
        </div>
        <v-btn
          color="primary"
          class="me-3"
          type="submit"
          :outlined="nextState"
          @click.prevent="onSave"
        >
          Let's Go!
          <v-icon
            dark
            right
            color="warning"
          >
            {{ icons.mdiRocketLaunch }}
          </v-icon>
          <v-icon
            v-if="nextState"
            dark
            right
            color="success"
          >
            {{ icons.mdiCheckboxMarkedCircleOutline }}
          </v-icon>
        </v-btn>
      </v-col>
      <!-- <v-spacer></v-spacer>
      <v-btn
        color="error"
        outlined
        @click="onSave"
      >
        Close
      </v-btn>
      <v-btn
        color="success"
        @click="onSave"
      >
        Save
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
    mdiLanPending, mdiRocketLaunch, mdiBottleTonicPlus, mdiFax
} from '@mdi/js'
import { useVisitStore } from '@/stores/Visit.js'
import { statusColor, statusLabel } from '@/assets/lists'
import { visitService } from '@/api/index.js'
import { getCurrentTimestamp, getTimeDifference } from '@/util/functions/index.js'

export default {
    name: 'CallToAction',
    props: {
        prevButton: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            nextState: null,

            disabledd: false,

            // radios: this.visitStore.faxStatus.status === 'success' ? 'other' : 'pharmaSmart',
            radios: '',
            disabled: false,
        }
    },
    setup() {
        const visitStore = useVisitStore()

        return {
            visitStore,
            statusColor,
            statusLabel,
            icons: {
                mdiLanPending,
                mdiRocketLaunch,
                mdiBottleTonicPlus,
                mdiFax
            },
        }
    },
    computed: {
        overlayClass() {
            return this.radios !== 'pharmaSmart' ? 'hidden-img tw-h-12' : 'tw-h-12'
        },

        disableddd() {
            if (getTimeDifference(this.visitStore.visitData.status[1].timeStamp) > 15) {
                return true
            }

            return false
        }
    },
    mounted() {
        // if (this.visitStore.visitData.status[1].value === 'transfer') {
        //     this.radios = 'other'
        // } else {
        //     this.radios = 'pharmaSmart'
        // }

        this.disabledd = (getTimeDifference(this.visitStore.visitData.status[1].timeStamp) > 15)
        this.radios = this.visitStore.visitData.status[1].value === 'transfer' ? 'other' : 'pharmaSmart'
        this.disabled = this.visitStore.faxStatus?.status === 'success' || this.visitStore.faxStatus?.status === 'transmitting' || this.disabledd || this.visitStore.visitData.status[1].value === 'complete'

        this.visitStore.$subscribe(() => {
            this.disabledd = (getTimeDifference(this.visitStore.visitData.status[1].timeStamp) > 15)
            this.radios = this.visitStore.visitData.status[1].value === 'transfer' ? 'other' : 'pharmaSmart'
            this.disabled = this.visitStore.faxStatus?.status === 'success' || this.visitStore.faxStatus?.status === 'transmitting' || this.disabledd || this.visitStore.visitData.status[1].value === 'complete'
        })
    },
    methods: {
        help() {
            this.$zendesk.show()
            this.$zendesk.toggle()
        },
        previous() {
            this.$emit('previous', 3)
        },
        onSave() {
            if (this.visitStore.visitData.status[1].value !== 'complete') {
                if (this.radios === 'pharmaSmart') {
                    const data = {
                        processing: 'inprogress',

                    // basic: 'complete',
                    // payment: 'pending',
                    // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                }
                if (this.radios === 'other') {
                    const data = {
                        processing: 'transfer',

                    // basic: 'complete',
                    // payment: 'pending',
                    // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                }
            }
            this.$emit('closeDialog', false)
            this.$emit('update:is-bio-dialog-open', false)
        },
        async updateVisitStatus(data) {
            const statusData = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                visitId: this.visitStore.visitId,
                patientId: this.visitStore.patientData.id,
                processing: data.processing || 'pending',

                // basic: data.basic || 'pending',
                // payment: data.payment || 'pending',
                // delivery: data.delivery || 'pending',
            }
            await visitService.updateVisitStatus(statusData)
            await this.visitStore.setVisit({ id: statusData.visitId, dob: statusData.dateofBirth })
            this.nextState = true
        },
    },
}
</script>

<style scoped>
#deliveryImage,
#cocImage,
#portalImage,
#samplesImages {
  height: 3.5rem;
  width: 100px;
  border-radius: 5px;
  position: relative;
}

#wordBreak {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

/* .hidden-img, #deliveryImage:after { */
.hidden-img:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '\274c'; /* use the hex value here... */
  font-size: 50px;
  text-align: center;
  line-height: 50px;
}
</style>
